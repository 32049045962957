<template>
    <div class="up-load">
        <div class="upload-btn">
            <el-button size="small" type="primary" :disabled="list.length>=Number(max)"
                       v-if="!previewShow">点击上传
            </el-button>
            <el-button v-else
                       size="small"
                       :loading="loading"
                       type="primary"
                       :disabled="urlList.length>=Number(max)">点击上传
            </el-button>
            <slot></slot>
            <input type="file" @change="getFile($event,'excel_path')" :multiple="multiple" ref="file"
                   :disabled="list.length>=Number(max)"/>
        </div>
        <ul class="file-list" :class="{'bd':list.length>0}" v-if="!previewShow">
            <li v-for="(item,index) in list" :key="index" v-loading="item.loading">
                <span v-if="!previewShow">{{item.name}}</span>
                <span v-else @click="preview(urlList[index])">{{item.name}}</span>
                <i class="el-icon-error" @click="deleteImg(index,'excel_path')"></i>
            </li>
        </ul>
        <ul class="video-list" v-else>
            <li v-for="(item,index) in urlList" :key="index" v-loading="item.loading">
                <video :src="item"></video>
                <div>
                    <i class="el-icon-video-play" @click="preview(item)"></i>
                    <i class="el-icon-delete" @click="deleteImg(index,'excel_path')"></i>
                </div>

            </li>
        </ul>
        <el-dialog :visible.sync="dialogVisible"
                   :fullscreen="true"
                   custom-class="preview-dialog">
            <video :src="previewUrl" width="50%" controls="controls" style="max-height: 600px;"></video>
        </el-dialog>
    </div>
</template>
<script>
  //oss 上传大文件
  const aliOss = require('ali-oss')
  let client = null
  let tempCheckpoint;
  import Http from "@/service/http";
  // import {timestamp} from '@/assets/js/base'
  import md5 from 'js-md5';

  export default {
    props: {
      multiple: {
        type: Boolean,
        default: false
      },
      clear: {//清空数据
        type: Boolean,
        default: false
      },
      fileTypes: {
        type: Array,
        default: () => ['jpg', 'jpeg', 'png', 'bmp', 'docx', 'pdf', 'doc']
      },
      max: {//最多张数
        type: Number,
        default: 0
      },
      previewShow: {
        type: Boolean,
        default: false
      },
      initList: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        list: [],//展示的文件
        fileList: [], //上传的文件列表暂时存放，以防上传的次数不止一次
        // urlList: [],//上传的文件url列表
        loading: false,
        files: [],
        firstTime: true,
        previewUrl: '',//预览地址
        dialogVisible: false,

        // fileTypes: ['jpg', 'jpeg', 'png', 'bmp', 'docx', 'pdf', 'doc']
      }
    },
    computed: {
      urlList: {//上传的文件url列表
        get() {
          this.list=this.initList
          return this.initList
        },
        set(val) {
          return val
        },

      }
    },
    methods: {
      async getFile(event, flag) {//获取文件信息上传
        this.fullscreenLoading = true
        let files = event.target.files
        if (files.length > this.max || this.urlList.length >= this.max) {
          this.$message.error(`最多允许上传${this.max}张图片`)
          return false
        } else {
          for (let i = 0; i < files.length; i++) {
            let seat = ''
            let arr = files[i].name.split(".")
            seat = arr[arr.length - 1].toLowerCase();

            if (!this.fileTypes.includes(seat)) {
              this.$message.error(`${files[i].name}文件格式不支持,请重新上传`)
            } else {
              // if (files[i].size > 2097152) { //2M
              //   this.$message.error(`${files[i].name}图片大小超出2M,请重新上传`)
              // } else {
              // await this.getUrl(files[i])
              this.list.push(files[i])
              // this.filesList.push(files[i])
              // this.multipartUpload(files[i], i, files)
              // }
            }

          }
          this.$refs.file.value = null//清空后可以重新上传同张文件
          this.$emit('getList', this.list)
          // this.$emit('getInfo', {value: this.value, type: this.type})
        }

      },
      // async getUrl(file) {
      //   let res = await Http.electricChargeExport({'file': file},[], true)
      //   if (res.code === 200) {
      //     this.urlList.push(res.data.url)
      //     this.fullscreenLoading = false
      //   }
      // },
      deleteImg(idx) {
        this.urlList.splice(idx, 1)
        this.list.splice(idx, 1)
        this.$emit('getInfo', {value: this.value, type: this.type})
        this.$emit('getFile', this.urlList)
        this.$emit('getImgList', this.urlList)

      },
      clearFn() {
        this.fileList = []
        this.urlList = []
        this.$emit('getFile', this.urlList)
      },
    },

    watch: {
      urlList(newVal) {
        if (newVal) {
          this.urlList = newVal
        }
      }
    },
    created() {
    }
  }
</script>
<style lang="scss" scoped>
    .upload-btn {
        display: inline;
        position: relative;

        input[type='file'] {
            display: block !important;
            position: absolute;
            left: 0;
            top: -14px;
            z-index: 99999;
            width: 80px;
            height: 50px;
            opacity: 0;
        }
    }

    .file-list {
        margin-top: 10px;
        padding-left: 0;

        &.preview {
            li {
                cursor: pointer;
            }
        }

        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 5px;

            .el-icon-error {
                color: red;
            }

            &:hover {
                background-color: #f0f0f0;
                color: #409EFF;
            }
        }

        &.bd {
            border: 1px solid #f0f0f0;
        }
    }

    .video-list {
        padding: 0 !important;
        display: flex;

        li {
            width: 200px;
            height: 120px;

            margin-right: 10px;
            text-align: center;
            position: relative;
            line-height: 120px;
            transition: all 1s;

            &:hover {
                div {
                    display: block;
                }

            }

            div {
                display: none;
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                z-index: 22;
                background: rgba(255, 255, 255, .1);
                font-size: 20px;

                .el-icon-video-play {
                    color: #fff;
                    padding: 10px;
                }

                .el-icon-delete {
                    color: #fff;
                    padding: 10px;
                }

            }

            video {
                width: 100%;
                height: 100%;

            }

        }

    }

</style>
