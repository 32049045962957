<template>
    <div class="page1">

        <el-button type="primary" @click="createScreenFn" size="small">生成链接</el-button>
        <!--     <EButton type="primary" @click="dialogVisible = true">增加</EButton>-->
        <ETable
                :tableTitle="tableTitle"
                :tableData="tableData"
                :needPagination="false"
        >
            <el-table-column fixed="right" label="操作" width="180px">
                <template slot-scope="scope">
                    <div>
                        <el-button type="text" @click="goTo('SlidePicDetail',{linkAddress:scope.row.linkAddress,id:scope.row.screenConfigId})" > 编辑显示内容
                        </el-button>
                    </div>
                </template>
            </el-table-column>
        </ETable>
    </div>
</template>

<script>
  import ETable from '@/components/ETable';
  import EUpload from '@/components/EUpload';
  import Http from '@/service/http';
  import EDialog from '@/components/EDialog';
  import EButton from '@/components/EButton';
  import EForm from '@/components/EForm';
  import {identity_type, status} from '@/assets/js/config';
  import vxRule from '@/assets/js/formValidate';
  import {otherMixin} from '@/components/mixin';
  import {mapGetters} from 'vuex'

  export default {
    name: 'shopInfo',
    mixins: [otherMixin],
    data() {
      return {
        tableTitle: [
          {
            label: '链接地址',
            prop: 'linkAddress',
          },
          {
            label: '生成时间',
            prop: 'createTime',
          },
        ],
        tableData: [],
      };
    },
    watch: {},
    created() {
      this.getData();

    },
    components: {ETable, EDialog, EButton, EForm,EUpload},
    computed: {
      ...mapGetters(['buttonList']),
    },
    beforeMount() {
    },
    methods: {
      async createScreenFn(){
        let res = await Http.createScreen();
        if (res.code == 200) {
          this.getData()
        }
      },
      getList(data) {
        this.multiForm.file = data[0]
      },

      saveData(boothInformationId) {
        this.$refs['form'].validate((valid, object) => {
          if (valid) {
            this.boothInfoSubmit(boothInformationId);
          } else {
            return false;
          }
        });
      },
      onSearch() {
        this.searchForm.current = 1;
        this.getData();
      },
      changePage(current) {
        this.searchForm.current = current;
        this.getData();
      },
      changeSize(size) {
        this.searchForm.size = size;
        this.getData();
      },
      cancelDialog() {
        this.dialogVisible = false;
        this.dialogForm = {
          boothInformationId: '',
          boothCode: '',
          areas: '',
          areaId: '',
          boothUseId: '',
          placeId: '',
          boothTypeId: '',
          remark: '',
        };
        this.changeInfo(null);
        this.$refs.form.resetFields();
      },

      async getData() {

        let res = await Http.cmbigscreenconfigList();
        if (res.code == 200) {
          this.tableData = res.data
        }
      },
      remove(row) {
        this.$messageBox
            .confirm('确认删除?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            })
            .then(() => {
              this.boothInfoRemove(row.boothInformationId);
            })
            .catch(() => {
              this.$message({
                type: 'info',
                message: '已取消删除',
              });
            });
      },
      async boothInfoRemove(boothInformationId) {
        let res = await Http.boothInfoRemove({ids: boothInformationId});
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.getData();
        }
      },

    },
  };
</script>

<style lang="scss" scoped>
    .ws {
        width: 100%;
    }
</style>
